import { packageLocationTypes } from "@/hooks/esim/location-packages/types/locationPackage.types";

export const esimPlansTabs = [
  {
    id: 1,
    title: "Country Plans",
    value: "country-plans",
    locationType: packageLocationTypes.countries
  },
  {
    id: 2,
    title: "Region Plans",
    value: "region-plans",
    locationType: packageLocationTypes.regions
  },
  {
    id: 3,
    title: "Subregion Plans",
    value: "subregion-plans",
    locationType: packageLocationTypes.subregions
  }
];
