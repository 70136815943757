import { IApiResponse } from "@/api/interfaces/IApi";
import { Store } from "vuex";

interface editEsimPlanOperatorStatusDto {
  id: number;
  value: string;
  status: boolean;
  locationsKey: string;
  planId: number
}
export async function updatePlanOperatorStatus(
  options: editEsimPlanOperatorStatusDto,
  store: Store<any>
): Promise<IApiResponse> {
  const { id, status, value, planId, locationsKey } = options;
  const res = await store.dispatch("updatePlanOperatorStatus", {
    id,
    status,
    value,
    planId,
    locationsKey
  });

  const { success, message } = res;

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  return res;
}
