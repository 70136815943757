
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import AppExpendedTable from "@/components/Table/ts/AppExpendedTable.vue";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import { useEsimPlans } from "@/hooks/esim-plans/useEsimPlans";
import { ref } from "@vue/reactivity";
import { esimPlansTabs } from "@/hooks/esim-plans/instanse/esimPlansTabs";
import { defineComponent } from "@vue/runtime-core";
import PlansPlanCard from "@/components/Packages/eSIM/PlansPlanCard.vue";
import HeadToolBar from "@/components/Table/HeadToolBar.vue";
import { mapGetters } from "vuex";
import WarningConfirm from "@/components/ui/Modal/Dialog/instances/WarningConfirm.vue";
import { IPlanOperator } from "@/api/services/packages/esim/location-packages/types";
export default defineComponent({
  name: "ESIMPlans",
  components: {
    WarningConfirm,
    SelectDropdown,
    AppExpendedTable,
    HeadToolBar,
    AppTableController,
    PlansPlanCard,
    ShadowControl,
    PageMainHeading
  },
  setup() {
    const locationType = ref(esimPlansTabs[0].locationType);
    return {
      ...useEsimPlans(locationType),
      locationType
    };
  },
  data() {
    return {
      isWarning: false,
      planOperatorId: -1,
      planId: -1,
      countryPackagesFilter: [
        {
          id: "Show all",
          name: "Show all",
          selected: true
        },
        {
          id: "Empty only",
          name: "Empty only",
          selected: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      esimZones: "esimZones",
      esimTypes: "esimTypes"
    })
  },
  created() {
    this.$store.dispatch("fetchEsimZones");
    this.$store.dispatch("fetchEsimTypes");
  },
  methods: {
    checkChangeOperatorStatusHandler(
      {
        id,
        status,
        planOperators
      }: {
        id: number;
        status: boolean;
        planOperators: IPlanOperator[];
      },
      planId: number
    ) {
      this.planId = planId;
      if (!status) {
        const newArray = planOperators.filter(el => el.id !== id && el.active);
        if (!newArray.length) {
          this.isWarning = true;
          this.planOperatorId = id;
        } else {
          this.changeOperatorStatusHandler(id, status, planId);
        }
      } else {
        this.changeOperatorStatusHandler(id, status, planId);
      }
    },
    checkHandler() {
      this.changeOperatorStatusHandler(this.planOperatorId, false, this.planId);
      this.isWarning = false;
    }
  }
});
