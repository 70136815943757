
import Card from "@/components/ui/Card/Card.vue";
import AppSwitch from "@/components/Form/AppSwitch.vue";
import Partner from "@/components/ui/Partner.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import {
  IPlanOperator,
  IZone
} from "@/api/services/packages/esim/location-packages/types";
import { esimType } from "@/api/services/profiles/profiles.types";
import AppTabs from "@/components/AppTabs/AppTabs.vue";
import AppSelectInput from "@/components/Form/AppSelectInput.vue";
import WarningConfirm from "@/components/ui/Modal/Dialog/instances/WarningConfirm.vue";
export default defineComponent({
  name: "PlansPlanCard",
  components: {
    AppSelectInput,
    AppCheckbox,
    Partner,
    AppSwitch,
    Card,
    AppTabs
  },
  props: {
    id: { type: Number as PropType<number>, required: true },
    title: { type: String, required: true },
    zone: { type: Object as PropType<IZone>, default: () => {} },
    type: { type: Object as PropType<esimType>, default: () => {} },
    server: { type: Object as PropType<IZone>, default: () => {} },
    esimZones: { type: Array as PropType<IZone[]>, default: () => [] },
    esimTypes: { type: Array as PropType<esimType[]>, default: () => [] },
    checked: { type: Boolean, default: false },
    discount: {
      type: Boolean,
      required: true
    },
    discountAmount: {
      type: Number,
      required: true
    },
    onCheck: { type: Function, required: true },
    onZoneChange: { type: Function, required: true },
    onServerChange: { type: Function, required: true },
    onDiscountChange: { type: Function, required: true },
    carriers: {
      type: Array as PropType<IPlanOperator[]>,
      default: () => []
    },
    isCountry: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    onOperatorCheck: null
  },
  data() {
    return {
      freeSocials: false,
      isChecked: this.checked,
      isDisabled: false,
      selectedTab: 0,
      isWarning: false,
      nserver: this.server,
      discounts: ["Disabled", "Percentage"].map((el, idx) => ({
        title: el,
        id: idx,
        selected:
          this.discount && idx === 1 ? true : !this.discount && idx === 0
      })),
      discountsList: [10, 20, 30, 40, 50, 60, 70, 100].map((el, idx) => ({
        title: el + "%",
        value: el,
        id: idx,
        selected: el === this.discountAmount
      })),
      mappedZone: this.esimZones.map((element: any) => ({
        ...element,
        selected: element?.id === this.zone?.id
      })),
      mappedType: this.esimTypes.map((element: any) => ({
        ...element,
        selected: element.id === this.type?.id
      })),
      mappedServers: [] as Array<any>
    };
  },
  computed: {
    getTabs() {
      let value = [];
      if (this.isCountry) {
        value = ["Carriers", "Features", "Discount", "Types", "Zones"];
      } else {
        value = ["Carriers", "Features", "Discount", "Types"];
      }
      return value.map((el, idx) => ({ title: el, id: idx }));
    },
    getPlanOperators() {
      const newArray = [] as any;

      for (const item of this.carriers) {
        console.log({item})
        const { id, active, operator } = item;
        const country = operator?.countryName || "";
        if (newArray[country] && newArray[country]?.length && operator) {
          newArray[country].push({
            id,
            active,
            name: operator?.name?.toLowerCase(),
            title: operator?.name,
            image: operator?.image
          });
        } else {
          newArray[country] = [
            {
              id,
              active,
              name: operator?.name?.toLowerCase(),
              title: operator?.name,
              image: operator?.image
            }
          ];
        }
      }
      return Object.entries(newArray);
    },
    mappedDiscounts() {
      return this.discounts;
    },
    mappedDiscountsAmount() {
      return this.discountsList;
    },
    discountAvailable() {
      return this.discounts.some(
        el => el.title === "Percentage" && el.selected
      );
    }
  },
  watch: {
    isChecked(status) {
      this.onCheck(this.id, status);
    },
    mappedType: {
      handler(data) {
        this.mappedServers =
          data
            .find((element: any) => element.selected)
            ?.server.map((element: any) => ({
              ...element,
              selected: element.id === this.server?.id
            })) ?? [];
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isOperatorChecked(id: number, status: number) {
      this.$emit("onOperatorCheck", {
        id,
        status: !status,
        planOperators: this.carriers
      });
    },
    planZoneSelect(id: number) {
      const zone = this.esimZones.find((zone: any) => zone.id === id);
      this.onZoneChange(this.id, zone);
      this.mappedZone.forEach((zone: any) => (zone.selected = zone.id === id));
    },
    esimTypeSelect(id: number) {
      this.mappedType.forEach((type: any) => (type.selected = type.id === id));
    },
    esimServerSelect(serverId: number) {
      this.mappedServers.forEach(
        (server: any) => (server.selected = server.id === serverId)
      );
      const typeId = this.mappedType.find(type => type.selected).id;
      this.onServerChange({ serverId, typeId, planId: this.id });
    },
    async discountSelect(id: number) {
      this.discounts.forEach(el => (el.selected = el.id === id));
      const type = this.discounts.find(el => el.selected)?.title;
      if (type === "Disabled") {
        this.isDisabled = true;
        await this.onDiscountChange({
          planId: this.id,
          discount: false,
          discountAmount: 0
        });
        this.isDisabled = false;
      }
    },
    async discountAmountSelect(id: number) {
      this.discountsList.forEach(el => (el.selected = el.id === id));
      const type = this.discountsList.find(el => el.selected);
      this.isDisabled = true;
      await this.onDiscountChange({
        planId: this.id,
        discount: true,
        discountAmount: type?.value
      });
      this.isDisabled = false;
    },
    onOperatorCheckHandler() {}
  }
});
